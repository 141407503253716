const statusAtivoMixin = {
  computed: {
    statusAtivo() {
      return {
        S: {
          text: "Ativo",
          color: "success"
        },
        N: {
          text: "Inativo",
          color: "error"
        }
      };
    }
  }
};

export default statusAtivoMixin;
