<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <v-card max-width="700">
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            Lista de Logs: {{ process.descricao }}
          </span>
        </v-toolbar>
      </v-card-title>

      <template v-if="eachProcessLogs.length">
        <v-list-item v-for="(item, i) in eachProcessLogs" :key="i">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.mensagem }} -
              {{ item.data_proc | BrazilianStandardDateAndTime }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Processado por: {{ item.usuario }}
            </v-list-item-subtitle>
            <v-divider></v-divider>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item v-else>
        Não há dados processados nos ultimos 5 dias
      </v-list-item>

      <v-card-actions class="justify-end">
        <v-btn color="error" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import campanha from "@/services/http/campanhaService";
import dateMixin from "@/mixins/dateMixin";
export default {
  mixins: [dateMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    process: {
      type: Object
    }
  },

  data() {
    return {
      eachProcessLogs: [],
      dataInicial: new Date(),
      dataFinal: new Date()
    };
  },

  watch: {
    process(value) {
      this.fetchProcessLog(value.id_processo);
    }
  },

  methods: {
    async fetchProcessLog(idProcess) {
      let { data } = await campanha()
        .processLog()
        .show({
          per_page: -1,
          id_processo: idProcess,
          id_tipo_evento: 1,
          data_proc: [this.dataInicial, this.dataFinal]
        });
      this.eachProcessLogs = data.data;
    },

    formatDates() {
      this.dataFinal = this.dataFinal.toISOString().substr(0, 10);
      this.dataInicial.setDate(this.dataInicial.getDate() - 4);
      this.dataInicial = this.dataInicial.toISOString().substr(0, 10);
    },

    close() {
      this.$emit("close");
    }
  },

  mounted() {
    this.formatDates();
  }
};
</script>

<style></style>
