<template>
  <div class="text-center">
    <v-menu
      ref="menu"
      :value="menu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="textDate"
          :label="label"
          class="field-class"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:clear="formatDate()"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="pt-br"
        v-model="date"
        type="month"
        no-title
        scrollable
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePickerMonth",
  props: {
    label: {
      type: String,
      default: "Mês/Ano"
    },
    currentDate: {
      type: Boolean,
      default: true
    },
    resetDate: {
      type: Boolean,
      default: true
    },
    dateValue: {
      type: Object
    }
  },
  data() {
    return {
      date: "",
      textDate: "",
      menu: false
    };
  },
  methods: {
    formatDate(date) {
      if (!date) {
        this.$emit("date", {});
        return null;
      }
      const [year, month] = date.split("-");
      this.$emit("date", { ano_ref: parseInt(year), mes_ref: parseInt(month) });
      return `${month}/${year}`;
    }
  },

  watch: {
    date(value) {
      this.textDate = this.formatDate(value);
    },

    resetDate(value) {
      if (value != false) {
        this.date = new Date().toISOString().substr(0, 7);
      }
    }
  },
  mounted() {
    if (this.currentDate) this.date = new Date().toISOString().substr(0, 7);
    if (this.dateValue) {
      this.dateValue.mes_ref =
        this.dateValue.mes_ref < 10
          ? `0${this.dateValue.mes_ref}`
          : this.dateValue.mes_ref;
      this.date = `${this.dateValue.ano_ref}-${this.dateValue.mes_ref}`;
    }
  }
};
</script>
<style scoped>
.field-class {
  padding: 30px 0 8px !important;
}

.field-class >>> .v-input__control .v-input__slot .v-text-field__slot .v-label {
  top: -5px !important;
}

.field-class >>> .v-input__control .v-input__slot .v-text-field__slot input {
  margin-top: -7px !important;
}

.field-class >>> .v-input__control > .v-input__slot:before {
  bottom: -3px;
}
.field-class >>> .v-input__control > .v-input__slot:after {
  bottom: -3px;
}
.field-class >>> .v-input__prepend-inner {
  margin-top: -7px !important;
  margin-left: 4px !important;
}
</style>
