<template>
  <v-container class="container-card">
    <v-card>
      <ProcessamentoComissao />
    </v-card>
    <DialogProcessos
      :dialog="dialog"
      :logs="logsExecucaoProcessos"
      :processando="processando"
      @resetForm="resetListaProcessos"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DialogProcessos from "@/components/shared/DialogProcessos";
import ProcessamentoComissao from "@/components/comissao/processamento/ProcessamentoComissao";
// import Filters from "@/components/comissao/processamento/Filters";
import processamentoMixin from "@/mixins/processamentoMixin";
export default {
  name: "ProcessamentoDeComissao",

  components: {
    ProcessamentoComissao,
    DialogProcessos
    // Filters
  },
  mixins: [processamentoMixin],

  data() {
    return {
      form: this.$refs.Filters?.form
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    }),
    // executaParametrosProcesso() {
    //   if (this.$refs.ListaProcessos.processosSelecionados.length === 0) {
    //     return this.$notify({
    //       text: "Não existem processos selecionados",
    //       type: "warning"
    //     });
    //   }

    //   this.executaFilaDeProcessos(
    //     this.$refs.Filters.form,
    //     this.$refs.ListaProcessos.processosSelecionados,
    //     "C"
    //   );
    // },

    resetListaProcessos() {
      this.$refs.ListaProcessos.processosSelecionados = [];
      this.logsExecucaoProcessos = [];
      this.dialog = false;
    }
  },

  mounted() {
    this.fetchView("Processamento de Comissão");
  }
};
</script>
