import procedureService from "@/services/procedures";

const processamentoMixin = {
  data() {
    return {
      processando: false,
      processado: false,
      dialog: false,
      logsExecucaoProcessos: [],
      progress: []
    };
  },

  methods: {
    async executaFilaDeProcessos(form, processosSelecionados, tipoEvento) {
      this.processando = true;
      this.dialog = true;

      try {
        for await (let processo of processosSelecionados) {
          const { processa_por_empresa } = processo;

          processa_por_empresa == true
            ? await this.montaProcessoPorEmpresa(form, processo, tipoEvento)
            : await this.montaProcesso(form, processo, tipoEvento);
        }

        this.processando = false;
        this.processado = true;

        if (this.logsExecucaoProcessos.length > 0) return;

        this.$notify({
          type: "success",
          text: "Dados processados com sucesso"
        });

        this.resetListaProcessos();
      } catch (error) {
        this.processando = false;
        this.$notify({
          type: "error",
          text: "Houve um erro ao processar os dados"
        });
        this.resetListaProcessos();
      }
    },

    async montaProcesso(form, processo, tipoEvento) {
      const { mes, ano } = this.convertMesAno(form.anoMes);
      const data = {
        id_campanha: form.campanha,
        id_processo: processo.id_processo,
        descricao: processo.descricao,
        apaga: form.apaga === true ? "S" : "N",
        id_tipo_evento: tipoEvento,
        ano_ref: ano,
        mes_ref: mes
      };
      return await this.executaProcesso(data);
    },

    async montaProcessoPorEmpresa(form, processo, tipoEvento) {
      const promises = form.empresas.map(async empresa => {
        const { mes, ano } = this.convertMesAno(form.anoMes);
        const data = {
          id_campanha: form.campanha,
          id_processo: processo.id_processo,
          descricao: processo.descricao,
          id_empresa: empresa,
          apaga: form.apaga === true ? "S" : "N",
          id_tipo_evento: tipoEvento,
          ano_ref: ano,
          mes_ref: mes
        };
        return await this.executaProcesso(data);
      });
      await Promise.all(promises);
    },

    async executaProcesso(data) {
      try {
        await procedureService.processa(data);
      } catch (error) {
        this.logsExecucaoProcessos.push({
          status: "error",
          text: data.id_empresa
            ? `${data.descricao} na empresa ${data.id_empresa} falhou`
            : `${data.descricao} falhou`
        });
      }
    },

    convertMesAno(mesAno) {
      if (!mesAno) return { mes: null, ano: null };

      let [ano, mes] = mesAno.split("-");
      ano = parseInt(ano);
      mes = parseInt(mes);
      return { mes, ano };
    }
  }
};

export default processamentoMixin;
