var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-3"},[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('BaseFilter',{attrs:{"isCompany":true,"multiple":false,"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras"},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('BaseFilter',{attrs:{"multiple":true,"isCompany":true,"prepend-inner-icon":"mdi-domain","clearable":"","label":"Empresa","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
          id_band: _vm.filters.id_band,
          calcula_comissao: 'S',
          ativo: 'S'
        },"prependItem":true},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Colaborador","item-text":"colaborador","item-value":"id_colaborador","service":"sistemaService.colaboradorEmpresa","filters":{ id_empresa: _vm.filters.id_empresa, ativo: 'S' }},model:{value:(_vm.filters.id_colaborador),callback:function ($$v) {_vm.$set(_vm.filters, "id_colaborador", $$v)},expression:"filters.id_colaborador"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('DatePickerMonth',{staticStyle:{"margin-top":"-6px"},on:{"date":function($event){return _vm.assignDateFilter($event)}}})],1)],1),_c('v-divider',{staticClass:"ma-4"}),_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":_vm.$router.history.current.path == '/comissao/processamento' ? true : false,"actualPage":1,"lastPage":_vm.nLastPage,"item-key":"id_processo","show-select":"","sort-by":"ordem"},on:{"pageChange":function($event){return _vm.fetchProcedures($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Processos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.data_proc",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDateAndTime")(item.data_proc))+" ")]}},{key:"item.ativo",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.ativo].color,"dark":""},on:{"click":function($event){return _vm.statusItem(item)}}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.ativo].text)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openModal(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue-grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-cog ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver logs")])])]}}],null,true),model:{value:(_vm.processosSelecionados),callback:function ($$v) {_vm.processosSelecionados=$$v},expression:"processosSelecionados"}}),_c('v-divider',{staticClass:"ma-4"}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.sendProcessa()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cog")]),_vm._v("Processar ")],1),(_vm.$router.history.current.path == '/comissao/processamento')?_c('v-btn',{staticClass:"ml-4",attrs:{"text":""},on:{"click":function($event){return _vm.sendProcessaApagar()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-trash-can")]),_vm._v("Apagar Dados ")],1):_vm._e()],1),_c('ModalLog',{attrs:{"process":_vm.process,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }